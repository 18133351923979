import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/styles'
import { Container } from '@material-ui/core'
import styles from '../styles';
import NewsPost from '../components/NewsPost'
import clsx from 'clsx'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(theme => { return {
  ...styles(theme),
  content: {
    padding: 0,
    marginTop: "50px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0"
    },
  }
}})

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const classes = useStyles()
  let post = { node: data.markdownRemark } // get this into a format NewsPost understands
  return (
    <React.Fragment>
      <Helmet>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@jellygon" />
        <meta name="twitter:creator" content="@all_iver" />
        <meta property="og:url" content={"https://jellygon.com" + post.node.fields.slug} />
        <meta property="og:title" content={post.node.frontmatter.title} />
        <meta property="og:description" content={post.node.excerpt} />
        <meta property="og:image" content={post.node.frontmatter.image} />
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="md" className={clsx(classes.content)}>
          <NewsPost post={post} />
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      excerpt
      fields { slug }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image
      }
    }
  }
`